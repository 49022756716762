@media only screen and (max-width: 768px) {
  .pdf {
    position: absolute;
    display: flex;
    align-items: center;
    direction: rtl;
    flex-direction: column;
  }
}

@media only screen and (min-width: 769px) {
  .pdf {
    display: flex;
    align-items: center;
    direction: rtl;
    flex-direction: column;
  }
}